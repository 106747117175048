import React from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
  Link,
  Routes
} from "react-router-dom";
import Form from './Form';
import Validation from './Validation';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Form />} />
        <Route path='/validation' element={<Validation/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
