import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  fr: {
    translation: {
      "text1": "Félicitations !",
      "text2": "Vous avez remporté un lot {{client}} au grand jeu concours.",
      "text3": "Entrez vos coordonnées** dans le formulaire ci-dessous pour que l’on puisse vous envoyer votre lot.",
      "text4": "Attention, vous avez jusqu’au {{date}} pour remplir ce formulaire.",
      "text5": "Au-delà de cette date, vous ne pourrez plus recevoir votre lot.",
      "text6": "Le lot vous sera envoyé au magasin choisi.",
      "name": "Nom",
      "firstname": "Prénom",
      "email": "Email",
      "phone": "Téléphone",
      "address": "Adresse",
      "postalcode": "Code postal",
      "city": "Ville",
      "shop": "Magasin",
      "data": "J’accepte que mes données personnelles soient utilisées par {{client}}",
      "submit": "Valider",
      "required": "Champs obligatoires",
      "keep": "{{client}} conserve vos données pendant 3 mois"
    }
  },
  es: {
    translation: {
      "text1": "Enhorabuena.",
      "text2": "Has ganado un premio {{client}} en el gran concurso.",
      "text3": "Introduzca sus datos** en el siguiente formulario para que podamos enviarle su premio.",
      "text4": "Tenga en cuenta que tiene hasta el {{date}} para rellenar este formulario.",
      "text5": "Después de esta fecha, no podrá recibir su lote.",
      "text6": "El lote se enviará a la tienda elegida.",
      "name": "Apellido",
      "firstname": "Nombre",
      "email": "Email",
      "phone": "Teléfono",
      "address": "Dirección",
      "postalcode": "Código postal",
      "city": "Ciudad",
      "shop": "Tienda",
      "data": "Acepto el uso de mis datos personales por parte de {{client}}.",
      "submit": "Validar",
      "required": "Campos requeridos",
      "keep": "{{client}} conserva sus datos durante 3 meses"
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: process.env.REACT_APP_LANG, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;